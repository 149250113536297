import React from 'react';
import {getCaptcha, saveContact} from 'api';
import intl from 'react-intl-universal';
import './index.scss';
import $ from 'jquery';

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      form: {
        "code": "",
        "email": "",
        "keynote": "",
        "message": "",
        "name": "",
        "phone": "",
        "surnames": "",
        "title": "",
        getCaptcha: getCaptcha,
      },
      emailErr: false,
      errStatus: false,
      check1: false,
      check2: false,
      captcha: "/captcha?1"
    }
  }

  componentDidMount() {
    // $('.lightBox_overlay').on('touchmove', function (event) { // 监听滚动事件
    //   event.preventDefault(); // 禁止浏览器默认行为
    // })
  }

  changeCaptcha = () => {
    let num = Math.ceil(Math.random() * 10);//生成一个随机数（防止缓存）
    let temp = `/captcha?${num}`
    this.setState({captcha: temp})
  };

  save = (e) => {
    e.preventDefault();
    for(let key in this.state.form) {
      if(!this.state.form[key]) {
        this.setState({errStatus: true});
        return
      }
    }
    let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    if (!reg.test(this.state.form.email)) {
      this.setState({errStatus: true, emailErr: true});
      return
    }
    // console.log(11111,this.state.form)
    saveContact(this.state.form).then(res => {
      alert(res)
    })
  };

  onInputChange = (e, key) => {
    const val = e.target.value;
    let temp = this.state.form;
    temp[key] = val;
    this.setState({form: temp});
  };

  // back=()=> {
  //   this.props.close()
  // };

  render() {
    const {form, check1, check2, captcha, errStatus, emailErr} = this.state;
    return (
        <div className="lightBox_overlay">
          <div className="lightBox_ani"></div>
          <div className="lightBox">
            <div className="frameBLK is-line">
              <a id="lightbox_CLOSE" className="lightbox_CLOSE" href="javascript:history.go(-1);"><span></span></a>
              <div className="lightBox_scroll mCustomScrollbar _mCS_3 mCS-autoHide"
                   style={{overflow: 'auto'}}>
                <div id="mCSB_3"
                     style={{maxHeight: 'none'}} tabIndex="0">
                  <div id="mCSB_3_container" className="mCSB_container"
                       style={{position: 'relative', left: 0, top: 0, overflow: 'auto'}} dir="ltr">
                    <div id="divLOAD">
                      <div id="load">
                        <section className="contact_content">
                          <div className="TITLE hasBar">
                            <h3>{intl.get('Contact_Us')}</h3>
                            <p className="f14 fontSTYLE3 regular-font">{intl.get('Contact_Us_subtitle')}</p>
                          </div>

                          <form method="POST" id="contactForm">
                            <input name="_token" type="hidden" value="r9x56CxspPvn46LdxFYSoNTRzfoC9vC4918kqjCD"/>

                            <p className="f18">{intl.get('Contact_Us_required')}</p>

                            <ul className="contactForm">
                              <li><input className="required" type="text" placeholder={intl.get('Contact_Us_Subject')}
                                         data-required="true" value={form.title}
                                         onChange={(e) => this.onInputChange(e, 'title')}/>
                                {errStatus&&!form.title&&<div className="err-tip">{intl.get('please_enter_title')}</div>}
                              </li>

                              <li><input className="required" type="text" placeholder={intl.get('Contact_Us_Last')}
                                         value={form.surnames} data-required="true"
                                         onChange={(e) => this.onInputChange(e, 'surnames')}/>
                                {errStatus&&!form.surnames&&<div className="err-tip">{intl.get('please_enter_last_name')}</div>}
                              </li>
                              <li><input className="required" type="text" placeholder={intl.get('Contact_Us_First')}
                                         value={form.name} data-required="true"
                                         onChange={(e) => this.onInputChange(e, 'name')}/>
                                {errStatus&&!form.name&&<div className="err-tip">{intl.get('please_enter_first_name')}</div>}
                              </li>
                              <li><input className="required" type="email" placeholder="*E-MAIL"
                                         value={form.email} data-required="true"
                                         onChange={(e) => this.onInputChange(e, 'email')}/>
                                {errStatus&&!form.email&&<div className="err-tip">{intl.get('please_enter_email')}</div>}
                                {errStatus&&emailErr&&<div className="err-tip">{intl.get('email_err')}</div>}
                              </li>
                              <li><input className="required" type="number" placeholder={intl.get('Contact_Us_Phone')}
                                         value={form.phone} data-required="true"
                                         onChange={(e) => this.onInputChange(e, 'phone')}/>
                                {errStatus&&!form.phone&&<div className="err-tip">{intl.get('please_enter_phone_number')}</div>}
                              </li>
                              <li><input className="required" type="text" placeholder={intl.get('Contact_Us_Topic')}
                                         value={form.keynote} data-required="true"
                                         onChange={(e) => this.onInputChange(e, 'keynote')}/>
                                {errStatus&&!form.keynote&&<div className="err-tip">{intl.get('please_enter_main_point_of_contact')}</div>}
                              </li>
                              <li className="area"><textarea className="required" rows="6" placeholder={intl.get('Contact_Us_Message')}
                                            value={form.message} data-required="true"
                                            onChange={(e) => this.onInputChange(e, 'message')}></textarea>
                                {errStatus&&!form.message&&<div className="err-tip">{intl.get('please_enter_contact_information')}</div>}
                              </li>
                              <li className="capt"><a href="javascript:;" id="changeCap" onClick={this.changeCaptcha}>
                                <img src={captcha} alt="captcha"/></a>
                                <input className="required" type="text" placeholder={intl.get('Contact_Us_Code')}
                                       value={form.code} onChange={(e) => this.onInputChange(e, 'code')}
                                       data-required="true"/>
                                {errStatus&&!form.code&&<div className="err-tip">{intl.get('please_enter_verification_code')}</div>}
                              </li>
                            </ul>

                            <p className="note">
                              <input type="checkbox" id="c2" value={check1}
                                     onChange={(e) => this.setState({check1: e.target.value})}/>
                              <label htmlFor="c2"><span></span></label>
                              <span style={{
                                marginTop: '22px',
                                display: 'block',
                                fontSize: '.12rem',
                                color: '#40210F'
                              }}>{intl.get('Contact_Us_NEWSLETTER')}</span>
                            </p>
                            <p className="note note2" style={{marginTop: '0.3rem',marginBottom: '22px',height: '40px'}}>
                              <input type="checkbox" id="c1" value={check2}
                                     onChange={(e) => this.setState({check1: e.target.value})}/>
                              <label htmlFor="c1"><span></span></label>
                            </p>

                            <p className="regular-font">
                              {intl.get('Contact_Us_SUBMIT_tip')}
                            </p>
                            <a className="aaSubmit submitBtn" href="" onClick={this.save}><span>{intl.get('Contact_Us_SUBMIT')}</span></a>

                          </form>

                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="mCSB_3_scrollbar_vertical"
                     className="mCSB_scrollTools mCSB_3_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical"
                     style={{display: 'block'}}>
                  <div className="mCSB_draggerContainer">
                    <div id="mCSB_3_dragger_vertical" className="mCSB_dragger"
                         style={{
                           position: 'absolute',
                           minHeight: '50px',
                           top: '0px',
                           display: 'block',
                           height: '263px',
                           maxHeight: '668px'
                         }}>
                      <div className="mCSB_dragger_bar" style={{lineHeight: "50px"}}></div>
                    </div>
                    <div className="mCSB_draggerRail"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
