import React from 'react';
import './index.scss';
import {emit} from '../../emit.js';
import { selectLanguage, getLang, getLangList } from 'api';

export default class SelectLang extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: window.localStorage.getItem('lang'),
      /*langMap: [
        {
          text: '<span>简</span><span></span><span>中</span>',
          lang: '1'
        },
        {
          text: '<span>繁</span><span></span><span>中</span>',
          lang: '2'
        },
        {
          text: '<span>ENGLISH</span>',
          lang: '3'
        },
        {
          text: '<span>日</span><span>本</span><span>語</span>',
          lang: '4'
        },
      ]*/
      langMap: []
    }
  }

  componentDidMount() {
    getLangList().then(res=>{
      if(res.data&&res.data.length) {
        let langMap = [];
        res.data.map(item => {
          if (item.isValid === 1) {
            switch (item.lang) {
              case 'zh_CN':
                langMap.push({
                  text: '<span>简</span><span></span><span>中</span>',
                  lang: '1'
                });
                break;
              case 'zh_TW':
                langMap.push({
                  text: '<span>繁</span><span></span><span>中</span>',
                  lang: '2'
                });
                break;
              case 'en_US':
                langMap.push({
                  text: '<span>ENGLISH</span>',
                  lang: '3'
                });
                break;
              case 'ja_JP':
                langMap.push({
                  text: '<span>日</span><span>本</span><span>語</span>',
                  lang: '4'
                });
                break;
            }
          }
        });
        this.setState({langMap})
      }
    });
    // 获取语言
    getLang().then(res=>{
      switch (res.data) {
        case 'zh_CN':
          this.setState({lang: '1'});
          // this.changeLang('1')
          window.localStorage.setItem('lang', '1');
          break;
        case 'zh_TW':
          this.setState({lang: '2'});
          window.localStorage.setItem('lang', '2');
          break;
        case 'en_US':
          this.setState({lang: '3'});
          window.localStorage.setItem('lang', '3');
          break;
        case 'ja_JP':
          this.setState({lang: '4'});
          window.localStorage.setItem('lang', '4');
          break;
        default:
          this.setState({lang: '3'});
          window.localStorage.setItem('lang', '3');
          break;
      }
      /*if(res.data === "zh_CN") {
        this.setState({lang: '1'});
        // this.changeLang('1')
        window.localStorage.setItem('lang', '1');
      } else {
        this.setState({lang: '3'});
        // this.changeLang('3')
        window.localStorage.setItem('lang', '3');
      }*/

    })
  };

  changeLang = lang => {
    this.setState({lang});
    this.props.close();
    let langStr = '';
    switch (lang) {
      case '1':
        emit.emit('change_language', 'zh-CN');
        langStr = 'zh_CN';
        break;
      case '2':
        emit.emit('change_language', 'zh-TW');
        langStr = 'zh_TW';
        break;
      case '3':
        emit.emit('change_language', 'en-US');
        langStr = 'en_US';
        break;
      case '4':
        emit.emit('change_language', 'ja-JP');
        langStr = 'ja_JP';
        break;
      default:
        emit.emit('change_language', 'en-US');
        langStr = 'en_US';
        break;
    }
    /*if (lang === '1') {
      emit.emit('change_language', 'zh-CN');
      langStr = 'zh_CN'
    } else {
      emit.emit('change_language', 'en-US');
      langStr = 'en_US'
    }*/
    selectLanguage(langStr).then(res => {
      if (lang !== window.localStorage.getItem('lang')) {
        window.localStorage.setItem('lang', lang);
        window.location.reload()
      }
    });
  };

  render() {
    return (
      <div className='modal-box'>
        <div className="box">
          <div className="header">
            <span className="title">请选择您的语言 | Select Your Language</span>
            <span className="close" onClick={()=>this.props.close()}></span>
          </div>

          <div className="content">
            <div className="center-box">
              {
                this.state.langMap.map(item => {
                  const lang = this.state.lang
                  return (
                    <div key={item.lang}>
                      {item.lang === lang ? <div className="line"></div> : null}
                      <div
                        className={`
                          lang-item
                          ${item.lang === '3' ? 'lang-english' : 'cn'}
                          ${item.lang === lang ? 'active' : ''}
                        `}
                        onClick={() => this.changeLang(item.lang)}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      ></div>
                      {item.lang === lang ? <div className="line"></div> : null}
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

      </div>
    )
  }
}
