import React from 'react';
import {getRunWayList} from 'api';
import {getImg, getVideo, isVisible, checkFull, throttle} from "utils";
import {Link} from "react-router-dom";
import intl from 'react-intl-universal'
import ReactPlayer from "react-player";

import './index.scss';
import autoplay from "swiper/src/components/autoplay/autoplay";

export default class RunWay extends React.Component {
  constructor() {
    super();
    this.state = {
      list: []
    }
  }

  componentDidMount() {
    document.getElementById('root').scrollIntoView(true);
    getRunWayList().then(list => {
      if(list[0].voidUrl) {
        list[0].autoPlay = true;
        list[0].mutedPlayer = true;
      }
      this.setState({list});
      window.onscroll = throttle(() => {
        let list = this.state.list;
        list.map((item,i)=>{
          let player1 = document.getElementById(i);
          let temp = isVisible(player1);
          item.autoPlay = temp;

          // 进入自动播放模式，设为静音
          if(temp) {
            item.mutedPlayer = true;
          }
        });
        this.setState({list})
      });

      window.onresize = throttle(() => {
        // 全屏下，放开静音。无法识别哪个视频为全屏模式，故，全部放开
        let list = this.state.list;
        if (checkFull()) {
          list.map((item,i)=>{
            item.mutedPlayer = false
          })
        } else {
          list.map((item,i)=>{
            item.mutedPlayer = true
          })
        }
        this.setState({list})
      });
    })
  }

  componentWillUnmount() {
    window.onscroll = '';
    window.onresize = '';
  }

  // 视频播放时不显示标题
  onPlay(index) {
    let list = this.state.list;
    list.map((item, i) => {
      if (index === i) {
        item.hideTitle = true
      }
    });
    this.setState({list})
  }

  // 视频播放结束显示标题
  onEnded(index) {
    let list = this.state.list;
    list.map((item,i)=>{
      if (index === i) {
        item.hideTitle = false
      }
    });
    this.setState({list})
  }

  // 视频暂停显示标题
  onPause(index) {
    let list = this.state.list;
    list.map((item,i)=>{
      if (index === i) {
        item.hideTitle = false
      }
    });
    this.setState({list})
  }

  render() {
    const playIcon = (<span className="icon-play"></span>);
    return (
        <div className="runway-box">
          <span className="title">{intl.get('RUNWAY')}</span>
          <div className="title-box">
          </div>

          <div className="runway-list">
            {
              this.state.list.map((item,index) => {
                let voidUrl = [ {
                  "title" : "19AW_celebrities_video.mp4",
                  "file" : "/uploads/file/20200629/1593408544561027562.mp4"
                } ];
                let {url, alt} = getImg(item.imageUrl);
                // const {videoUrl, title} = voidUrl ? getVideo(voidUrl) : {};
                const {videoUrl, title} = item.voidUrl ? getVideo(item.voidUrl) : {};
                // item.title = item.title.replace(/\n/g, "<br/>");
                return (
                    <div className="item" key={item.id}>
                      <Link to={`/runway/detail?id=${item.id}`}>
                        {item.voidUrl ? (
                            <ReactPlayer className='react-player runway-player' id={index}
                                         light={false} playIcon={playIcon}
                                         url={videoUrl} width="100%" playsinline={true}
                                         playing={true} muted={true} loop={true}

                            />
                        ) : <img src={url} alt={alt}/>}
                        {(!item.hideTitle || !item.autoPlay) &&
                        <div className="title">{item.title}</div>}
                      </Link>
                    </div>
                )
              })
            }
          </div>
        </div>
    )
  }
}
