import React, {useEffect, useState} from 'react';
import { ROUTES_MAP } from './routesMap';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { app, AppContext } from './app-context';

import CommonFooter from '@/CommonFooter';
import CommonHeader from '@/CommonHeader';
import CommonNav from '@/CommonNav';
import Contact from './pages/contact/index';
import $ from 'jquery';

import SelectLang from '@/SelectLang';


export default function App() {
  const [navVisible, setNavVisible] = useState(false);
  const [selectLangVisible, setSelectLangVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);
  const [top, setTop] = useState(0);

  useEffect(() => {
    console.log(localStorage.getItem('firstInitLang'))
    if(localStorage.getItem('firstInitLang')) {
      setSelectLangVisible(false)
    } else {
      setSelectLangVisible(true)
    }

  }, []);

  // 跳转到contact页面之前记录当前页面的位置
  const getPageSnap = () =>  {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    // $('html').css({"overflow": 'hidden'});
    setContactVisible(true)
    setTop(scrollTop)
  };

  const closeContact = () => {
    setContactVisible(false)
    // $('html').css({"overflow": 'auto'});
    document.body.scrollTop = top
  };

  const changeLangVisible = (flag) => {
    localStorage.setItem('firstInitLang', true);
    setSelectLangVisible(flag)
  };

  const lang = localStorage.getItem('lang')
  const langClass = lang==='1' || lang==='2' ?'zh':'en';

  return (
    <Router>
      <div className={`app-box ${langClass}`}>

          {
              navVisible ?
                  <CommonNav
                      visible={navVisible}
                      close={() => setNavVisible(false)}
                      setLang = {() => changeLangVisible(true)}
                  />
                  : null
          }
          {
              selectLangVisible ? <SelectLang close={() => changeLangVisible(false)} /> : null
          }
          {
              contactVisible ? <Contact show={contactVisible} close={() => setContactVisible(false)}/> : null
          }
          <AppContext.Provider value={app}>
          <Switch>
            {ROUTES_MAP.map((route, i) => (
              <Route
                path={route.path}
                key={i}
                exact
                render={props => (
                  <>
                    <CommonHeader {...props} show={() => setNavVisible(true)} visible={navVisible}></CommonHeader>
                    <route.component {...props} routes={route.routes}/>

                  </>
                )}
              />
            ))}
          </Switch>
          </AppContext.Provider>
          <CommonFooter close={() => setNavVisible(false)}
                        setLang = {() => changeLangVisible(true)}
                        openContact = {getPageSnap}
                        closeContact = {closeContact}
          />
      </div>
    </Router>
  )
}