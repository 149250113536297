import React, {useState, useEffect} from 'react';
import Swiper from 'react-id-swiper';
import config from 'src/config';
import {getImg, getVideo, checkFull, throttle} from "utils";
import {Link} from "react-router-dom";
import {getHome} from 'api';
import './index.scss';
import intl from 'react-intl-universal';
import ReactPlayer from 'react-player';
import { Carousel } from 'antd';
import right from '../../assets/images/right.svg';
import $ from 'jquery';

export default function Home(props) {
  const [res, setRes] = useState({});
  const [swiper2, setSwiper2] = useState(null);
  const [swiper3, setSwiper3] = useState(null);
  const [swiper4, setSwiper4] = useState(null);
  const [swiper5, setSwiper5] = useState(null);
  const [radioTextVisible, toggleRadioText] = useState(true);
  const [autoPlay1, toggleAutoPlay1] = useState(false);
  const [autoPlay2, toggleAutoPlay2] = useState(false);
  const [mutedPlayer1, toggleMutedPlayer1] = useState(true);
  const [mutedPlayer2, toggleMutedPlayer2] = useState(true);
  const [showCookie, setCookieVisible] = useState(true);
  const [showMsg, setMsgVisible] = useState(false);

  window.onscroll = throttle(() => {
    let player1 = document.getElementById('player1');
    let player2 = document.getElementById('player2');
    let temp1 = isVisible(player1);
    let temp2 = isVisible(player2);

    toggleAutoPlay1(temp1);
    toggleAutoPlay2(temp2);
    // this.setState({autoPlay1: temp1 });
    // this.setState({autoPlay2: temp2 });
    // 进入自动播放模式，设为静音
    if(temp1) {
      toggleMutedPlayer1(true);
      // this.setState({mutedPlayer1: true})
    }
    if(temp2) {
      toggleMutedPlayer2(true);
      // this.setState({mutedPlayer2: true})
    }
  });

  window.onresize = throttle(() => {
    // 全屏下，放开静音。无法识别哪个视频为全屏模式，故，全部放开
    if (checkFull()) {
      toggleMutedPlayer1(false);
      toggleMutedPlayer2(false);
      // this.setState({mutedPlayer1: false, mutedPlayer2: false})
    } else {
      toggleMutedPlayer1(true);
      toggleMutedPlayer2(true);
      // this.setState({mutedPlayer1: true, mutedPlayer2: true})
    }
  });

  useEffect(() => {
    if(localStorage.getItem('showCookie')) {
      setCookieVisible(false)
    }
    getHome().then(list => {
      setRes(list);
      getMsg(list)
    });
    return function cleanUp() {
      window.onscroll = ''
    }
  }, []);

  const getMsg = (res) => {
    let msg = localStorage.getItem('actMsg');
    let tempFlag = false;
    // getHome().then(res => {
      // 推送消息
      if (!msg && res.pushContentResponse && typeof res.pushContentResponse.content !== 'undefined') {
        tempFlag = true;
        localStorage.setItem('actMsg', JSON.stringify(res.pushContentResponse))
      } else if (msg && res.pushContentResponse && typeof res.pushContentResponse.content !== 'undefined') {
        let {time, readTime} = JSON.parse(msg);
        if (readTime && time) {
          let month = new Date(readTime).getMonth();
          let date = new Date(readTime).getDate();
          let currMonth = new Date().getMonth();
          let currDate = new Date().getDate();
          if (date !== currDate) {
            tempFlag = true
          } else {
            if (month === currMonth) { // 同一个月的同一天
              // 同一时间发布的同一条消息不再显示
              tempFlag = time !== res.pushContentResponse.time;
            } else { // 不同月的同一天，显示消息
              tempFlag = true
            }
          }
        } else {
          tempFlag = true;
        }
      }

      setMsgVisible(tempFlag)

      // 渲染msg
      // this.showMessage(res, tempFlag)

    // });
  };

  // 关闭推送消息
  const closeMsg = () => {
    // 在存储当前的关闭时间
    let readTime = new Date().getTime();
    let storage = JSON.parse(localStorage.getItem('actMsg'));
    let newStorage = {...storage, readTime};
    localStorage.setItem('actMsg', JSON.stringify(newStorage));
    setMsgVisible(false)
  };

  const closeCookie = (flag) => {
    setCookieVisible(false);
    if(flag===1) {
      localStorage.setItem('showCookie', '1')
    }
  };

  if (!res.indexUI) {
    return <div className="home-box"></div>
  }

  const imageSwipper1Params = {
    containerClass: 'banner1-box',
    effect : 'fade',
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    dynamicBullets: true,
    autoplay: true,
    delay: 3000,
    // autoplay: {
    //   delay: 2500,
    //   disableOnInteraction: false
    // },
    spaceBetween: 0,

    progress:true,
    onProgressChange: function(swiper){
      for (var i = 0; i < swiper.slides.length; i++){
        var slide = swiper.slides[i];
        var progress = slide.progress;
        var translate = progress*swiper.width;
        var opacity = 1 - Math.min(Math.abs(progress),1);
        slide.style.opacity = opacity;
        swiper.setTransform(slide,'translate3d('+translate+'px,0,0)');
      }
    },
    onTouchStart:function(swiper){
      for (var i = 0; i < swiper.slides.length; i++){
        swiper.setTransition(swiper.slides[i], 0);
      }
    },
    onSetWrapperTransition: function(swiper, speed) {
      for (var i = 0; i < swiper.slides.length; i++){
        swiper.setTransition(swiper.slides[i], speed);
      }
    }

  };

  // 元素是否在视频中央
  const isVisible = (dom) => {
    if (dom) {
      let rect = dom.getBoundingClientRect();
      return (
          rect.top >= 0 && rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  };

  const swiper2Prev = () => swiper2.slidePrev();
  const swiper2Next = () => swiper2.slideNext();
  const swiper3Prev = () => swiper3.slidePrev();
  const swiper3Next = () => swiper3.slideNext();
  const swiper4Prev = () => swiper4.slidePrev();
  const swiper4Next = () => swiper4.slideNext();
  const swiper5Prev = () => swiper4.slidePrev();
  const swiper5Next = () => swiper4.slideNext();

  const imageSwipper2Params = {
    containerClass: 'banner2-box',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    dynamicBullets: true,
    loop: true,
    navigation: {
      nextEl: '.second-swiper-button-next',
      prevEl: '.second-swiper-button-prev'
    },
    renderPrevButton: () =>
        <div className="second-swiper-button-prev arrow-left icon" onClick={swiper2Prev}></div>,
    renderNextButton: () =>
        <div className="second-swiper-button-next arrow-right icon" onClick={swiper2Next}></div>,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    // spaceBetween: 100,
  };

  const imageSwipper3Params = {
    containerClass: 'image-list3',
    slidesPerView: 2,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // autoplay: {
    //   delay: 2500,
    //   disableOnInteraction: false
    // },
    renderPrevButton: () =>
        <div className="second-swiper-button-prev arrow-left icon" onClick={swiper3Prev}></div>,
    renderNextButton: () =>
        <div className="second-swiper-button-next arrow-right icon" onClick={swiper3Next}></div>,
    spaceBetween: 6
  };

  const imageSwipper4Params = {
    containerClass: 'image-list3',
    slidesPerView: 2,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    dynamicBullets: true,
    loop: true,
    navigation: {
      nextEl: '.second-swiper-button-next',
      prevEl: '.second-swiper-button-prev'
    },
    // autoplay: {
    //   delay: 2500,
    //   disableOnInteraction: false
    // },
    spaceBetween: 6,
    renderPrevButton: () =>
        <div className="second-swiper-button-prev arrow-left icon" onClick={swiper4Prev}></div>,
    renderNextButton: () =>
        <div className="second-swiper-button-next arrow-right icon" onClick={swiper4Next}></div>,
  };

  const imageSwipper5Params = {
    containerClass: 'image-list3',
    slidesPerView: 2,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    dynamicBullets: true,
    loop: true,
    navigation: {
      nextEl: '.second-swiper-button-next',
      prevEl: '.second-swiper-button-prev'
    },
   /* autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },*/
    spaceBetween: 6,
    renderPrevButton: () =>
        <div className="second-swiper-button-prev arrow-left icon" onClick={swiper5Prev}></div>,
    renderNextButton: () =>
        <div className="second-swiper-button-next arrow-right icon" onClick={swiper5Next}></div>,
  };

  const indexUI = res.indexUI
  let part1, part2, part3, part4, part5, storyDes;
  indexUI.map(item => {
    if (item.id === 1) {
      part1 = item
    } else if (item.id === 4) {
      part2 = item
    } else if (item.id === 10) {
      part3 = item
    } else if (item.id === 11) {
      part4 = item
    } else if (item.id === 3) {
      part5 = item;
      storyDes = item.des
    }
  });

  const part1List = part1.pcIndexBlockResponses;
  const part2List = part2.pcIndexBlockResponses;
  const part3List = part3.pcIndexBlockResponses;
  const part3SomeList = part3List.slice(1);
  const part4List = part4.pcIndexBlockResponses;
  const part4SomeList = part4List.slice(1);
  const part5List = part5.pcIndexBlockResponses;


  const videoList = res.indexVoidResponses;
  const play = (<span className="icon-play"></span>);

  return (
      <div className="home-box">
        { showCookie &&
        <div className="cookie-tip">
          <div className="tip">{intl.getHTML("cookie_tip")}</div>
          <div className="close_cookie">
            <span onClick={()=>closeCookie(1)}><img src={right}/>{intl.get('accept_cookie')}</span>
            <span className="close icon" style={{color: '#40210F'}}  onClick={closeCookie}></span>
          </div>
        </div>}
        {showMsg&&res.pushContentResponse&&typeof res.pushContentResponse.content!=='undefined'&&
        <div className="home-message">
          <span>{res.pushContentResponse.content}</span>
          <span className="close-msg" style={{color: '#EFEADD'}}  onClick={closeMsg}></span>
        </div>}
        {/* <div class="video-wrapper"> 
        <div id="player" class="video-container"> 
         <iframe width="1024" height="576" src="https://player-letsee-tv.akamaized.net/embed4/6w9c1k" frameborder="0" allowfullscreen=""></iframe> 
        </div>
        </div> */}
        {/* part1 首图轮播 */}
        <div className="part1-swiper">
          <Carousel effect="fade" autoplay dots="swiper-pagination" speed={2000}
                    autoplaySpeed={5000}
                    dotsClass={'swiper-pagination'}>
            {
              part1List.map(banner => {
                let {url, alt} = getImg(banner.backImg);
                return (
                    <div className="item" key={banner.id}>
                      <div className="swiper-image" style={{backgroundImage: 'url('+url+')'}}></div>
                      <div className="bottom-cover"
                      style={{background: banner.isTrue===1?(banner.wordBgColor? `linear-gradient(${banner.wordBgColor})`:'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.7))'):"transparent"}}
                      ></div>
                      <div className="theme bottom" style={{color: banner.wordColor ? banner.wordColor : '#efeadd'}}>
                        <div className="sub-title">{banner.subName}</div>
                        <div className="title" style={{width: '3rem'}}>{banner.title}</div>
                        <a href={banner.url}>
                          <div className="discover" style={{color: banner.wordColor ? banner.wordColor : '#E7DED0', border: banner.wordColor ? '1px solid ' + banner.wordColor : '1px solid #efeadd'}}>
                            {banner.buttonName?banner.buttonName:intl.get('DISCOVER_NOW')}
                          </div>
                        </a>
                      </div>
                    </div>
                )
              })
            }
          </Carousel>
        </div>

        {/* part2 横排多图banner */}
        <div className="part2">
          <div className="main-title">{part2.name}</div>
          <div className="main-desc">{part2.des}</div>

          <div className="part2-swipper">
            <Swiper {...imageSwipper2Params} getSwiper={setSwiper2}>
              {
                part2List.map(banner => {
                  const {url, alt} = getImg(banner.backImg)
                  return (
                      <div className="item" key={banner.id}>
                        <img src={url} alt={alt}/>
                        <div className="sub">{banner.title}</div>
                        <div className="shop">
                          <a href={banner.url} className="part2-link">{banner.buttonName?banner.buttonName:'SHOP NOW'}</a>
                        </div>
                      </div>
                  )
                })
              }
            </Swiper>
          </div>
        </div>

        {/* part3 单图，一横三小 */}
        <div className="part3">
          {
            part3List.length ?
                <>
                  <div className="top">
                    <div className="main-title">{part3.name}</div>
                    <div className="main-desc">{part3.des}</div>
                  </div>
                  {
                    part3List.map((item, index) => {
                      if (!index) {
                        const {url, alt} = getImg(item.backImg);
                        return (
                            <div className="big-image-box" key={item.id}>
                              <img src={url} alt={alt}/>
                              <div className="theme">
                                <div className="title wrap" style={{color: item.wordColor ? item.wordColor : '#E7DED0'}}>{item.title}</div>
                                <a href={item.url}>
                                  <div className="discover" style={{color: item.wordColor ? item.wordColor : '#E7DED0', border: item.wordColor ? '1px solid ' + item.wordColor : '1px solid #EFEADD'}}>{item.buttonName?item.buttonName:intl.get('DISCOVER_NOW')}</div>
                                </a>
                              </div>
                            </div>
                        )
                      }
                    })
                  }

                  <div className="part3-swiper split-line">
                    <Swiper {...imageSwipper3Params} getSwiper={setSwiper3}>
                      {
                        part3SomeList.map((item,index) => {
                          const {url, alt} = getImg(item.backImg);
                          return (
                              <a className="item" href={item.url} key={item.id}>
                                <img src={url} alt={alt}/>
                                <div className="title" style={{fontSize: '0.11rem!important'}}>{item.title}</div>
                              </a>
                          )
                        })
                      }
                    </Swiper>
                  </div>

                </>
                : null
          }
        </div>

        {/* part4 单图，一横三小 */}
        <div className="part3 safe">
          {
            part4List.length ?
                <>
                  <div className="top">
                    <div className="main-title">{part4.name}</div>
                    <div className="main-desc">{part4.des}</div>
                  </div>
                  {
                    part4List.map((item, index) => {
                      const {url, alt} = getImg(item.backImg)
                      if (!index) {
                        return (
                            <div className="big-image-box" key={item.id}>
                              <img src={url} alt={alt}/>
                              <div className="theme">
                                <div className="title wrap" style={{color: item.wordColor ? item.wordColor : '#E7DED0'}}>{item.title}</div>
                                <a href={item.url}>
                                  <div className="discover" style={{color: item.wordColor ? item.wordColor : '#E7DED0', border: item.wordColor ? '1px solid ' + item.wordColor : '1px solid #EFEADD'}}>{item.buttonName?item.buttonName:intl.get('DISCOVER_NOW')}</div>
                                </a>
                              </div>
                            </div>
                        )
                      }
                    })
                  }

                  <div className="part3-swiper split-line">
                    <Swiper {...imageSwipper4Params} getSwiper={setSwiper4}>
                      {
                        part4SomeList.map(item => {
                          const {url, alt} = getImg(item.backImg);
                          return (
                              <a className="item" href={item.url} key={item.id}>
                                <img src={url} alt={alt}/>
                                <div className="title ellipsis" style={{fontSize: '0.15rem!important'}}>{item.title}</div>
                              </a>
                          )
                        })
                      }
                    </Swiper>
                  </div>

                </>
                : null
          }
        </div>

        {/* 视频*/}
        {videoList.length && (<div className="part6 safe">
          {
            videoList.map((item, index) => {
              const {url, alt} = getImg(item.image);
              const {videoUrl, title} = getVideo(item.voidUrl);
              let playIcon = (<div className="video-middle"><span className="icon-play"></span><span
                  className="video-title">{item.title}</span></div>);
              return (
                  <div className={`big-video-box ${index === 0 ? 'split-line' : ''}`} key={item.id}>
                    <div className="title">
                      {index > 0 ?
                          (<div className="top">
                            <Link to={'/stories'}>{intl.get('STORIES')}</Link>
                            <div className="main-desc">{storyDes}</div>
                          </div>) :
                          <Link to={'/runway'}>{item.title}</Link>}
                    </div>
                    <div className='player-wrapper player-wrapper-text'
                         style={{paddingTop: index > 0 ? '110%' : '56.25%'}}>
                      {index > 0&&radioTextVisible ? (
                          <div className="text-con">
                            <div className="title1">{item.title}</div>
                            <div className="sub-title">{item.subName}</div>
                          </div>
                      ) : ''}
                      {index === 0 && <a href={item.link} className="videoLink"><ReactPlayer className='react-player' url={videoUrl}
                                                   playing={autoPlay1} playsinline={true}
                                                   light={autoPlay1 ? false : url}
                                                   muted={mutedPlayer1} width='100%'
                                                   height='100%' playIcon={index < 1 ? playIcon : play}
                                                   id={`player${index + 1}`}
                                                   onPlay={()=>toggleRadioText(false)} onEnded={()=>toggleRadioText(true)}/></a>}
                      {index === 1 && <a href={item.link} className="videoLink"><ReactPlayer className='react-player' url={videoUrl}
                                                   playing={autoPlay2} playsinline={true}
                                                   light={autoPlay2 ? false : url}
                                                   muted={mutedPlayer2} width='100%'
                                                   height='100%' playIcon={index < 1 ? playIcon : play}
                                                   id={`player${index + 1}`}
                                                   onPlay={()=>toggleRadioText(false)} onEnded={()=>toggleRadioText(true)}/></a>}
                    </div>
                  </div>
              )
            })
          }
        </div>)}

        {/* part5 3图，三小 */}
        <div className="part3 safe part7" style={{padding: '.06rem 0', width: 'calc(100% - 0.12rem)'}}>
          <div className="part3-swiper">
            <Swiper {...imageSwipper5Params} getSwiper={setSwiper5}>
              {
                part5List.map(item => {
                  const {url, alt} = getImg(item.backImg);
                  return (
                      <a className="item" href={item.url} key={item.id}>
                        <img src={url} alt={alt} style={{height: '1.15rem', width: '1.51rem'}}/>
                        <div className="title ellipsis" style={{fontSize: '0.11rem'}}>{item.title}</div>
                        <div className="sub-title ellipsis" style={{fontSize: '0.11rem'}}>{item.subName} ></div>
                      </a>
                  )
                })
              }
            </Swiper>
          </div>
        </div>

        {/*回到顶部*/}
        <div className="top-box" onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>
          <div className="arrow">
            <div className="arrow-up icon"></div>
          </div>
          <div className="txt">{intl.get('Top')}</div>
        </div>

      </div>
  )
}
