import React from 'react';
import { getQuery, getImg } from 'utils';
import { getLookBookList } from 'api';
import { Link } from "react-router-dom";
import $ from "jquery";
import './index.scss';

export default class LookBookList extends React.Component {
  constructor() {
    super()
    this.state = {
      res: {},
      list: [],
      lookBookId: 0,
      lookBookDetailVisible: false,
      runwayId: '',
      maskVisible: false,
      currentId: ''
    }
  }

  componentDidMount() {
    // getLookBookList(this.props.id).then(list => this.setState({ list }))
    const id = getQuery('id'); // runwayId
    this.setState({runwayId: id});
    sessionStorage.setItem('lookbook',1);
    getLookBookList(id).then(res => {
      console.log(res)
      this.setState({res});
      this.setState({list: res.responses});

      for(let i=0; i< res.responses.length; i++) {
        $(`#img${i}`).fadeIn(Math.round(Math.random()*4000))
      }

      if(sessionStorage.getItem('lookbookId')) {
        this.setState({maskVisible: true, currentId: Number(sessionStorage.getItem('lookbookId'))});
      }
    });
  };

  toggleMask = (item, index) => {
    this.setState({maskVisible: true, currentId: item.id});
    sessionStorage.setItem('lookbookId', item.id)
  };

  render() {
    const {list} = this.state;
    // 列表根据id由小到大排序
    if (list && list.length) {
      list.sort((a, b) => {
        return a.id - b.id
      })
    }

    return (
      <div className="look-book-list-box">
        <div className="top">
          <div className="close-box" onClick={this.props.history.goBack}>
            <span className="txt">close</span>
            <span className="close icon"></span>
            {/*<img src={require('../../assets/images/close.png')}/>*/}
          </div>
          <div className="title">{this.state.res.title}</div>
          <div className="sub-title">{this.state.res.subTitle}</div>
        </div>

        <div className="panel">
          <div className="list clearfix">
            {
              list && list.map((item, i) => {
                const {url, alt} = getImg(item.imageUrl);
                return (
                    <div className="item" key={item.id}>
                      <Link to={`/lookbook/detail?id=${item.id}&runwayId=${this.state.runwayId}&index=${i}`}
                            key={item.id}>
                      <img id={'img'+i} src={url} alt={alt} style={{backgroundColor: '#00000020'}}
                           onClick={() => this.toggleMask(item,i) }/>
                      {this.state.maskVisible && this.state.currentId===item.id &&
                      <div className="mask">

                          <div className="title-con">
                            <div className="title">RUNWAY</div>
                            <div className="title2">{item.title}</div>
                            <div className="num">{i + 1}</div>
                          </div>

                      </div>}
                        </Link>
                    </div>
                )
              })
            }
          </div>
        </div>

      </div>
    )
  }
}

