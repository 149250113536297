import React from 'react';
import {getImg, getVideo} from 'utils';
import {Link} from "react-router-dom";
import './index.scss';
import intl from 'react-intl-universal';
import ReactPlayer from 'react-player';
import $ from 'jquery'

export default class Craftmanship extends React.Component {
  constructor() {
    super();
    this.state = {
    }
  }

  componentDidMount() {
    // 获取视频的数量
    let length = 0;
    this.props.data.craftImageResponses && this.props.data.craftImageResponses.map((item, index) => {
      if(item.voidUrl&&item.voidUrl[0].title.indexOf('.mp4')>-1) {
        length++
      }
    });

    //JS绑定自动播放（操作window时，播放音乐）
    $(window).one('touchstart', function () {
      for (let i = 0; i < length; i++) {
        $("video")[i].play();
      }

    });

    //微信下兼容处理
    document.addEventListener("WeixinJSBridgeReady", function () {
      for (let i = 0; i < length; i++) {
        $("video")[i].play();
      }
    }, false);
  }

  componentDidUpdate() {
    // console.log('props', this.props)
  }


  render() {
    const {data} = this.props;
    const {url, alt} = data.craftImageResponses ? getImg(data.craftImageResponses[data.craftImageResponses.length - 1].imageUrl) : {
      url: '',
      alt: ''
    };

    return (
        <div className="craftmanship-main">
          <div className="sub-link">
            <div className="link-con">
              {data.craftPathResponses && data.craftPathResponses.map((item, i) => {
                if (item.title) {
                  return (
                      <a href={item.path} key={i}>
                        <div className="item">{item.title}</div>
                      </a>
                  )
                }
              }
              )}
            </div>
          </div>
          <div className="img-flex">
            {data.craftImageResponses && data.craftImageResponses.map((item, index) => {
              let videoUrl = '';
              if (index < 9) {
                let fileTitle = item.voidUrl ? item.voidUrl[0].title.toLowerCase() : '.png';
                let {url: url1, alt: alt1} = getImg(item.imageUrl);
                if (item.voidUrl) {
                  videoUrl = getVideo(item.voidUrl).videoUrl;
                }

                if (fileTitle.indexOf('.png') > -1 || fileTitle.indexOf('.jpg') > -1 || fileTitle.indexOf('.jpeg') > -1 || fileTitle.indexOf('.bmp') > -1) {
                  return <img src={url1} alt={alt1} key={index}
                              style={{position: index > -1 && index < 3 ? 'relative' : ''}}/>
                } else {
                  return (
                      <video ref='video' src={videoUrl} muted={true} width='100%' height='100%'
                             poster={url1} loop autoPlay key={index}
                             style={{position: index > -1 && index < 3 ? 'relative' : ''}}
                             x5-playsinline='' playsInline='' webkit-playsinline=''></video>
                  )
                }
              }
            })
            }
          </div>

          <div className="text">
            <div className="title">{data.title}</div>
            <div className="content">
              <div dangerouslySetInnerHTML={{__html: data.content}}></div>
            </div>
          </div>

          <div className="bottom-img-panel">
            <div className="img-left">CRAFTSMANSHIP</div>
            <div className="img-right">
              {url && <img src={url} alt={alt}/>}
            </div>
          </div>
        </div>
    )
  }
}