import request from 'utils/request';
// 头部
export const getHead = () => request('/home/heard')

// footer
export const getFooter = () => request('/home/bottom')

// 首页
export const getHome = () => request(`/home/index`)

// 获取语言
export const getLang = () => request(`/home/get/language`)
// 获取语言列表
export const getLangList = () => request(`/home/language/list`);

// 选择语言
export const selectLanguage = (data) =>
    request({
        url: `/home/selectLanguage?nowLanguage=${data}`,
        method: 'post'
    })

// 订阅
export const subscribe = email => request({
    url:  `/common/subscribe?email=${email}`,
    method: 'post'
})

// 时装秀列表
export const getRunWayList = () => request('/runway/list')

// 时尚秀详情
export const getRunWayDetail = id => request(`/runway/details?id=${id}`)

// 广告列表
export const getAdvertisingList = runwayId => request(`/runway/advertising/list?runwayId=${runwayId}`)

// 广告详情
export const getAdvertisingDetail = id => request(`/runway/advertising/details?id=${id}`)

export const getLookBookList = runwayId => request(`/runway/lookBook/list?runwayId=${runwayId}`)

export const getLookBookDetail = id => request(`/runway/lookBook/details?id=${id}`)

// 故事列表
export const getStoryList = () => request('/story/list')

// 故事详情
export const getStoryDetail = id => request(`/story/details?id=${id}`)

// 国家
export const getCountryList = () => request('/store/country/list')

// 城市
export const getCityList = id => request(`/store/city/list?id=${id}`)

// 店
export const getStoreList = id => request(`/store/list?id=${id}`)

// 查询品牌书
export const getBrandBook = id => request(`/brand/get/book`)

// 查询品牌工艺
export const getBrandCraft = id => request(`/brand/get/craft`)

// 查询品牌故事
export const getBrandStory = id => request(`/brand/get/story`)

// 隐私
export const getPrivacy = () => request(`/home/privacyNotice`);

// 获取验证码
export const getCaptcha = () => {
  let num=Math.ceil(Math.random()*10);//生成一个随机数（防止缓存）
  return request(`/captcha?${num}`)
};

// 联系我们
export const saveContact = (params) => request({url:`/contact/save/contact`, data:params, method: 'post'});

// login
export const login = (userName,password) => request({url:`/passport/login?userName=${userName}&password=${password}`, method: 'post'});

// register
export const register = (params) => request({url:`/passport/register`, data: params, method: 'post'});

// 搜索最近30公里店铺
export const getNearestStore = (lng, lat) => request({url:`/store/list/location?lng=${lng}&lat=${lat}`, method: 'get'});


// 查询购物车列表
export const getList = () => request(`/cart/getList`);

// 查询精品店预约列表
export const getReservationList = () => request(`/cart/getBoutique`);

/*start 把下面的接口按登录、未登录分类成数组，index=0为登录，index=1为未登录*/
// 获得购物车||精品店预约的商品数量 type: 1购物车 2心愿单 3预约单
export const getCartNum1 = [
  (type) => request({url:`/cart/getCartNum?type=${type}`, method: 'post'}),
  (type) => request({url:`/cart/querySessionCartNum?type=${type}`, method: 'post'})
];

// 移除购物车内的商品 || 删除精品店内容
export const removeFromCart1 = [
  (id) => request({url:`/cart/removeFromCart?shoppingCartItemId=${id}`, method: 'post'}),
  (id) => request({url:`/cart/removeFromSessionCart?formId=${id}`, method: 'post'})
];

// 购物车||精品店 爱心图标（加入心愿单）
export const cartToWish1 = [
  (id) => request({url:`/cart/cartToWish?shoppingCartItemId=${id}`, method: 'post'}),
  (id) => request({url:`/cart/changeFromSessionCartType?formId=${id}&goalType=2`, method: 'post'})
];

// 购物车下:点击包包，加入精品店
export const cartToBoutique1 = [
  (id) => request({url:`/cart/cartToBoutique?shoppingCartItemId=${id}`, method: 'post'}),
  (id) => request({url:`/cart/changeFromSessionCartType?formId=${id}&goalType=3`, method: 'post'})
];

// 精品店下:点击包包，加入购物车
export const boutiqueToCart1 = [
  (id) => request({url:`/cart/boutiqueToCart?shoppingCartItemId=${id}`, method: 'post'}),
  (id) => request({url:`/cart/changeFromSessionCartType?formId=${id}&goalType=1`, method: 'post'})
];

/*end 把下面的接口按登录、未登录分类成数组，index=0为未登录，index=1为登录*/


// 登录后获取用户信息
export const getUserInfo = () => request(`/home/user`);
export const getEmail = () => request(`/contact/get/email`);
export const getIcon = () => request(`/home/icon`);
