import React, {useState, useEffect, useContext} from 'react';
import {getHead,getCartNum1} from 'api';
import {AppContext} from 'src/app-context';
import './index.scss';
import {Link} from "react-router-dom";
import userImg from '../../assets/images/user.svg';
import $ from "jquery";

export default function CommonHeader(props) {
  const {setNavList, getNavigationResponses} = useContext(AppContext);
  // const [navigationResponses, setNavigationResponses] = useState([]);
  let navigationResponses = [{link: ''}, {link: ''}];


  const [xihuanNum,setXihuanNum] =useState(0)


  useEffect(() => {
    // 百度统计
    setTimeout(()=>{
      var _hmt = _hmt || [];
      (function() {
        //每次执行前，先移除上次插入的代码
        document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?1c7b6bb5f759b2ffda106e3d6edd5869";
        hm.id = "baidu_tj"
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
      props.history.listen((location, action) => {
        // location is an object like window.location
        // console.log(action, location.pathname, location.state)
        // debugger
        window._hmt.push(['_trackPageview', location.pathname]);
      });
    });



    getHead().then(list => {
      setNavList(list.navigationForPcResponses.reverse());
      getNavigationResponses(list.navigationResponses);
      // setNavigationResponses(list.navigationResponses)
      navigationResponses = list.navigationResponses
    })
  }, []);



  console.log("登录状态：", localStorage.getItem('userInfo'))
  let loginStatus = localStorage.getItem('userInfo') ? 0 : 1;

  getCartNum1[loginStatus](2).then(item=>{
    console.log(item);
    setXihuanNum(item.data);
  })

  console.log(xihuanNum);

  const showNav = () => {
    $('#open').addClass('open');
    setTimeout(() => {
      props.show()
    }, 500)
  };

  //在线客服地址
  let lang = localStorage.getItem('lang');
  let onlineServerUrl = "https://shiatzychen.soboten.com/chat/pc/v2/index.html?sysnum=5c9e23bf21894d1e87f68939845f5d21&channelid=2&locale="
  let newOnlineServerUrl = "";
  let newOnlineServerContent = ""
  if(lang==="3"){
    newOnlineServerUrl = onlineServerUrl+"en"
    newOnlineServerContent = "ONLINE SERVICE"
  }else{
    newOnlineServerUrl = onlineServerUrl+"cn"
    newOnlineServerContent = "在线客服"
  }




  return (
      <div className="header-box">
        <div className="top-box">
          <div className="left">
            <div id='open' className={`more ${!props.visible?'':'open'}`} onClick={showNav}>
              <div id="hamburger">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <Link to='/'>
              <div className="logo bg"></div>
            </Link>
          </div>
          <div className="opt-box">
            <div className="item">
              <a href='/cart/list'>
                <div className="shopping-bag bg">
                  <span className="iconfont icon-gouwudai block"></span>
                </div>
                <div className="count">0</div>
              </a>
            </div>
            <div className="item">
              <a href='/boutique/list'>
                <div className="shop bg">
                  <span className="iconfont icon-jingpindian block"></span>
                </div>
                <div className="count">0</div>
              </a>
            </div>
            <div className="item">
              <a href='/cart/wishlist'>
                <div className="shop bg">
                  <span className="iconfont icon-xihuan block"></span>
                </div>
                <div className="count">{xihuanNum}</div>
              </a>
            </div>
            <div className="item">
              <a href='/u/account/index'>
                <div className="user bg">
                  <img className="user-logo" src={userImg}/>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
  )
}
