import React from 'react'
import Swiper from "react-id-swiper"
import {getRunWayList} from 'api'
import {getImg} from 'utils'
import './index.scss'
import intl from 'react-intl-universal'

export default class Book extends React.Component {
  constructor() {
    super()
    this.state = {
      list: []
    }
  }

  componentDidMount() {

  }


  render() {
    const {data} = this.props;
    const {url, alt} = data.coverImageUrl ? getImg(data.coverImageUrl) : {
      url: '',
      alt: ''
    };

    const imageSwipperParams = {
      containerClass: 'book-swipper-box',
      observer: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      dynamicBullets: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      }
    }

    return (
        <div className="book-main">
          <div className="flex">
            <img src={url} alt={alt}/>
            <div className="title">
              {data.title}
            </div>
            <div className="sub-title">
              {data.subtitle}
            </div>
            <div className="book-content">
              <div dangerouslySetInnerHTML={{__html: data.content}}></div>
            </div>
          </div>

          <div className="text">VIEW THE BOOK</div>
          <div className="img-swipper">
            {data.imageUrl && data.imageUrl.length && <Swiper {...imageSwipperParams}>
              {data.imageUrl && data.imageUrl.map((item, i) => {
                const {url} = getImg([item]);
                return (
                    <div className="img-item" key={i}>
                      <img src={url}/>
                    </div>)
              })}
            </Swiper>}
          </div>
        </div>
    )
  }
}
