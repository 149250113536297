import React from 'react'
import { getBrandBook, getBrandStory, getBrandCraft } from 'api'
import { Tabs } from 'antd-mobile'
import './index.scss'
import intl from 'react-intl-universal'
import BrandStory from '../brandStory'
import Craftmanship from '../craftmanship'
import Book from '../book'
import { getQuery } from 'utils'

export default class TabContainer extends React.Component {
  constructor () {
    super()
    this.state = {
      list: [],
      tab: '2',
      craftRes: {},
      bookRes: {},
      storyRes: {},
      tabs: [],
      defaultActiveKey: '2'
    }
  }

  //组件更新时被调用
  componentWillReceiveProps(nextProps){
    console.log('更新:', nextProps)
    // 获取浏览器地址携带的tab参数
    const currentTab = getQuery('tab')
    if (currentTab) {
      this.setState({ defaultActiveKey: currentTab })
      document.getElementById('root').scrollIntoView(true);
    }
  }

  componentDidMount () {
    document.getElementById('root').scrollIntoView(true)
    let name1 = intl.get('BRAND_STORY')

    let tabs = [{ title: intl.get('BRAND_STORY'), sub: '3' },
      { title: intl.get('THE_CRAFTMANSHIP'), sub: '2' },
      { title: intl.get('THE_BOOK'), sub: '1' }]
    this.setState({ tabs })
    this.getBrandCraft()
    this.getBrandBook()
    this.getBrandStory()
    // 获取浏览器地址携带的tab参数
    const currentTab = getQuery('tab');
    if(currentTab) {
      this.setState({defaultActiveKey: currentTab});
    }
  }

  // 查询品牌故事
  getBrandStory = () => {
    getBrandStory().then(res => {
      // console.log(res, 'getBrandStory')
      res.imageResponses = res.imageResponses.reverse()
      this.setState({ storyRes: res })
    })
  }

  // 查询品牌书
  getBrandBook = () => {
    getBrandBook().then(res => {
      // console.log(res, 'getBrandBook')
      this.setState({ bookRes: res })
    })
  }

  // 查询品牌工艺
  getBrandCraft = () => {
    getBrandCraft().then(res => {
      // console.log(res, 'getBrandCraft')
      this.setState({ craftRes: res })
    })
  }

  handleChange = (tab) => {
    console.log(tab)
    this.setState({ tab: tab.sub, defaultActiveKey: tab.sub });
    this.props.history.push('/brandStory?tab='+tab.sub)
  }

  render () {
    const { tabs } = this.state
    return (
        <div className="tab-con">
          <Tabs tabs={ [{ title: intl.get('BRAND_STORY'), sub: '3' },
            { title: intl.get('THE_CRAFTMANSHIP'), sub: '2' },
            { title: intl.get('THE_BOOK'), sub: '1' }] } animated={ false } swipeable={false}
                page={ Number(this.state.defaultActiveKey) === 3 ? 0 : (Number(this.state.defaultActiveKey) === 2 ? 1 : 2) }
                tabBarStyle={ { textAlign: 'center', borderBottom: 'none' } } onChange={ this.handleChange }>
            <div className="tab-container">
              <BrandStory tab={ this.state.tab } data={ this.state.storyRes }/>
            </div>
            <div className="tab-container">
              <Craftmanship tab={ this.state.tab } data={ this.state.craftRes }/>
            </div>
            <div className="tab-container">
              <Book tab={ this.state.tab } data={ this.state.bookRes }/>
            </div>
          </Tabs>
        </div>
    )
  }
}
