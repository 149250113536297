import React from 'react';
import './index.scss';
import markIcon from 'assets/images/location.png';
import $ from 'jquery';

export default class MapLocation extends React.Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.marker = [];
    this.markerArray = [];
    this.showCity = true;
    this.mapType = 'A'
  }

  // 高德地图
  loadAMap = () => {
    this.mapType = 'A';
    const url = `https://webapi.amap.com/maps?v=1.4.15&key=77a3e473be34d81c13a53e3240b95070&callback=onAMapLoad&&plugin=AMap.Scale,AMap.OverView,AMap.ToolBar`
    const jsapi = document.createElement('script')
    jsapi.charset = 'utf-8'
    jsapi.src = url
    document.head.appendChild(jsapi);

    window.onAMapLoad = () => {
      console.log('地图已加载')
      var toolBar = new window.AMap.ToolBar({visible: true})
      this.map = new window.AMap.Map('map-box', {
        mapStyle: "amap://styles/whitesmoke",
        center: [116.406315, 39.908775],
        zoom: 14
      })
      this.map.addControl(toolBar);
      this.updateMap();
    }
  };

  loadBMap = () => {
    this.mapType = 'B';
    const url = `https://api.map.baidu.com/api?v=3.0&ak=GgHjw11mitCe2jvbdaYB31EzxOIxNCj8&callback=onBMapLoad`
    const jsapi = document.createElement('script')
    jsapi.charset = 'utf-8'
    jsapi.src = url
    document.head.appendChild(jsapi);

    window.onBMapLoad = () => {
      console.log('百度地图已加载')
      // var toolBar = new window.AMap.ToolBar({
      //   visible: true
      // })
      this.map = new window.BMap.Map('map-box');
      this.map.addControl(new window.BMap.NavigationControl());
      // this.map.addControl(new window.BMap.ScaleControl());
      // this.map.addControl(new window.BMap.OverviewMapControl());
      // this.map.addControl(new window.BMap.MapTypeControl());
      this.map.enableScrollWheelZoom(true);
      // var ggPoint = new window.BMap.Point(116.406315, 39.908775);
      // this.map.centerAndZoom(ggPoint, 11);
      this.updateMap();
    }
  };

  // 创建iframe
  createIframe = () => {
    let iframe;
    return function () {
      if (iframe) {
        return iframe;
      } else {
        iframe = document.createElement('iframe')
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        return iframe;
      }
    }
  };

  createScheme = (options, link) => {
    // 定义App的scheme,参数由options传入
    // let urlScheme = 'comgooglemaps://?center=40.765819,-73.975866&zoom=15';
    let urlScheme = link;
    if(options) {
      urlScheme = urlScheme + '?'
    }
    for (let item in options) {
      urlScheme = urlScheme + item + '=' + encodeURIComponent(options[item]) + '&';
    }
    urlScheme = urlScheme.substring(0, urlScheme.length - 1);
    return encodeURIComponent(urlScheme);
  };

  // 判断安卓
  isAndroid() {
    var u = navigator.userAgent;
    if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
      if (window.ShowFitness !== undefined) return true;
    }
    return false;
  }

  // 判断设备为 ios
  isIos() {
    var u = navigator.userAgent;
    if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
      return true;
    }
    return false;
  }

  isChrome() {
    var userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
    return !!(navigator.userAgent.indexOf('Chrome') > -1)
  }

  openApp = (localPoint) => {
    let option = null;
    let localUrl = null;
    if (localStorage.getItem('lang') === '1') {
      /*if (localPoint) {
        // localPoint = localPoint.split(',');
        if (this.isIos) {
          console.log('pass1')
          // localUrl = "iosamap://path?t=0&slat=" + localPoint[0] + "&slon=" + localPoint[1];
          localUrl = "https://uri.amap.com/marker?position="+localPoint+'&callnative=1';
        } else {
          localUrl = 'amapuri://route/plan?sourceApplication=amap&amp;slat='+ localPoint[0] +'&amp;slon='+ localPoint[1];
        }
      } else {
        console.log('pass2')
        if (this.isIos) {
          localUrl = "iosamap://navi?sourceApplication=amap&dev=1&style=2";
        } else {
          localUrl = 'androidamap://navi?sourceApplication=amap&dev=1&amp;style=2';
        }
      }*/

    } else {
      if (localPoint) {
        option = {
          center: localPoint,
          zoom: '15'
        };
      }
      localUrl = this.createScheme(option,'comgooglemaps://');
    }
    let openIframe = this.createIframe();

    if (this.isIos) {
      // 判断是否是ios
      window.location.href = localUrl;
      const loadDateTime = Date.now();
      setTimeout(function () {
        const timeOutDateTime = Date.now();
        if (timeOutDateTime - loadDateTime < 1000) {
          window.location.href = "Download URL";
        }
      }, 25);
    } else if (this.isAndroid) {
      // 判断是否是安卓
      if (this.isChrome) {
        // Chrome浏览器用iframe打不开
        window.location.href = localUrl;
      } else {
        // 抛出scheme
        openIframe.src = localUrl;
      }
      setTimeout(function () {
        window.location.href = "Download URL";
      }, 500);
    } else {
      // 给winphone用户准备
      openIframe.src = localUrl;
      setTimeout(function () {
        window.location.href = "Download URL";
      }, 500);
    }
  };

  openGaode=(point,name)=>{
    const gaode = document.createElement('a');
    let href='https://uri.amap.com/marker?position='+point+'&name='+name+'&callnative=1';
    gaode.setAttribute('href', href);
    document.head.appendChild(gaode);
    gaode.click();
  };

  openBaidu=(store)=>{
    const baidu = document.createElement('a');
    let pp = store.localPoint.split(',')
    let href='https://api.map.baidu.com/marker?location='+pp[1]+','+pp[0]+'&title='+store.name+'&content='+store.address+'&output=html&src=webapp.baidu.openAPIdemo';
    baidu.setAttribute('href', href);
    document.head.appendChild(baidu);
    baidu.click();
  };

  openGoogle=(address)=> {
    const a = document.createElement('a');
    let href='https://www.google.com/maps/search/'+address;
    a.setAttribute('href', href);
    document.head.appendChild(a);
    a.click();
  };

  updateMap = () => {
    if (this.mapType === 'A') {
      const icon = new window.AMap.Icon({
        size: new window.AMap.Size(12, 28),
        image: markIcon
      });
      // set city
      if (this.showCity) {
        if (this.props.location) {
          this.map.setCity(this.props.location + '市');
        }
      }
      if (this.marker.length) {
        this.map.remove(this.marker)
        this.marker = []
      }
      // set store
      for (let item of this.props.storeList) {
        this.marker.push(new window.AMap.Marker({
              icon,
              position: item.localPoint.split(","),
              anchor: 'bottom-center',
              // offset: new window.AMap.Pixel(0, 0),
              label: {content: item.name, direction: 'center', offset: new window.AMap.Pixel(0, -45)}
            })
        );
      }
      if (this.marker.length) {
        this.marker.map((marker,index) => {
          marker.setMap(this.map)
          let point = '';

          if(this.props.storeList&&this.props.storeList.length&&typeof this.props.storeList[index].localPoint !== 'undefined')
            point = this.props.storeList[index].localPoint;
            let name=this.props.storeList[index].name;
          // 点击marker唤起app
          window.AMap.event.addListener(marker, 'click', ()=>this.openGaode(point,name));

        })
      }

    } else if (this.mapType === 'B') {
      var that = this;
      var myIcon = new window.BMap.Icon(markIcon, new window.BMap.Size(12, 28), {
        imageSize: new window.BMap.Size(12, 28)
      });
      if (this.showCity) {
        if (this.props.storeList.length > 0) {
          // var pp = this.props.storeList[0].localPoint.split(",")
          // var ggpoint = new window.BMap.Point(pp[0], pp[1]);
          this.map.centerAndZoom(this.props.location);
          // this.map.setCenter(ggpoint)
          // this.map.setZoom(11)
        }
      }
      if (this.marker.length) {
        this.map.clearOverlays();
        this.marker = []
      }
      // set store
      for (let item of this.props.storeList) {
        // console.log(item)
        var pp2 = item.localPoint.split(",");
        var ggpoint2 = new window.BMap.Point(pp2[0], pp2[1]);
        var label = new window.BMap.Label(item.name,{offset:new window.BMap.Size(15,2)});
        var marker = new window.BMap.Marker(ggpoint2, {icon: myIcon});
        label.setStyle({
          color: '#00000085',
          backgroundColor: "#fff",
          border: "1px solid #d4d4d4"
        })
        marker.setLabel(label); //添加百度label
        marker.addEventListener("click", function(){
          that.openBaidu(item)
        });
        this.map.addOverlay(marker);
      }
    } else {
      if (this.props.storeList.length <= 0) return
      let firstPoint = this.props.storeList[0].localPoint ? this.props.storeList[0].localPoint.split(",") : [];
      this.map.setCenter(new window.google.maps.LatLng(firstPoint[1], firstPoint[0]))

      // 清空上次搜索的所有标记
      if(this.marker.length) {
        this.marker.map(marker=>{
          marker.setMap(null);
          // window.google.maps&&window.google.maps.event.removeListener(marker, 'click', ()=>this.openApp());
        });
        this.marker=[]
      }
      this.props.storeList.map((item, index) => {
        let newPoint = item.localPoint.split(",");
        this.marker.push(new window.google.maps.Marker({
              position: new window.google.maps.LatLng(newPoint[1], newPoint[0]),
              icon: {url: markIcon, labelOrigin: new window.google.maps.Point(5, -10)},
              map: this.map,
              label: {text: item.name, color: '#00000085', fontWeight: 'bolder', fontSize: '0.12rem'}
            })
        );

      });

      // 重新设置此次搜索的所有标记
      if(this.marker.length) {
        this.marker.map((marker,index)=>{
          // console.log('marker:', marker)
          marker.setMap(marker);

          let localPoint = '';
          // console.log('localpoint======>',this.props, 'marker===>',index)
          if(this.props.storeList&&this.props.storeList.length&&this.props.storeList[index].localPoint)
           localPoint = this.props.storeList[index].localPoint;
          let address = this.props.storeList[index].address;
          // 点击marker唤起app
          // window.google.maps.event.addListener(marker, 'click', ()=>this.openApp(localPoint));
          window.google.maps.event.addListener(marker, 'click', ()=>this.openGoogle(address));
        })
      }

    }
  };

  // 谷歌地图
  loadGoogleMap = () => {
    window.google = {};
    // const googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAXM8Qzay_fYN4pgAFXkPZDNCptHOyYrBA&libraries=places&callback=initGoogleMap`;
    let lang = localStorage.getItem('lang');
    let googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap`;
    switch (lang) {
      case '2':
        googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap&language=zh&region=TW`;
        break;
      case '3':
        googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap&language=en&region=US`;
        break;
      case '4':
        googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap&language=ja&region=JP`;
        break;
      default:
        googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap&language=en&region=US`;
        break;
    }
    const that = this;
    $.ajax({
      url: googleMapUrl,
      type: "get",
      dataType: "jsonp",
      success: function () {
        console.log(1111111)
        const jsapi = document.createElement('script');
        jsapi.charset = 'utf-8';
        jsapi.src = googleMapUrl;
        document.head.appendChild(jsapi);
        that.mapType = 'G';
      },
      error: function (err) {
        console.log(2222222,err)
        that.loadBMap();
      }
    });

    window.initGoogleMap = () => {
      var uluru = {lat: -25.344, lng: 131.036};

      this.map = new window.google.maps.Map(document.getElementById("map-box"), {
        center: uluru,
        zoom: 14,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
      });
      /*let marker = new window.google.maps.Marker({
        position: uluru,
        icon: markIcon,
        map: this.map
      });*/
    }
  };

  // 改变地图中心点及缩放级别
  changePoint(point, flag) {
    let newPoint = point ? point.split(',') : '';
    if (this.mapType === 'A') {
      if (flag) {
        this.showCity = false
      } else {
        this.showCity = true
      }
      this.map.setZoomAndCenter(24, newPoint);
    } else if (this.mapType === 'B') {
      newPoint = newPoint.map(item => item = Number(item));

      if (flag) {
        this.showCity = false
      } else {
        this.showCity = true
      }
      // console.log(newPoint)
      var ggpoint = new window.BMap.Point(newPoint[0], newPoint[1]);
      this.map.centerAndZoom(ggpoint, 18);
    } else {
      if (window.google && newPoint.length && this.map) {
        this.map.setZoom(15);
        this.map.setCenter(new window.google.maps.LatLng(newPoint[1], newPoint[0]))
      } else if (window.google && !newPoint.length) {
        this.map.setCenter(new window.google.maps.LatLng('', ''))
      }
    }
  }

  componentDidMount() {
    // 加载高德地图
    if (localStorage.getItem('lang') === '1') {
      // 中文模式下用高德
      this.loadBMap();
      // this.loadAMap();
    } else {
      // 加载谷歌地图
      this.loadGoogleMap()
      this.createIframe()
    }
    this.props.onRef(this)
  }


  render() {
    if (this.map) this.updateMap();
    return (
        <div id="map-box"/>
    )
  }
}
