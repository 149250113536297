import React from 'react';
import {getCountryList, getCityList, getStoreList, getNearestStore} from 'api';
import './index.scss';
import intl from 'react-intl-universal';
import MapLocation from '@/MapLocation';

export default class Store extends React.Component {
  constructor() {
    super();
    this.state = {
      countryList: [],
      cityList: [],
      storeList: [],
      country: {},
      city: {},
      showMap: false,
      optionVisible: {
        country: false,
        city: false
      },
      position: {
        lng: 0,
        lat: 0
      },
      storeType: 0, // 点击搜索 0，附近30公里 1ap
      showCity: true
    }
  }

  // 进入页面强制滚动到顶部
  componentWillMount() {
    document.getElementById('root').scrollIntoView(true); // 为ture返回顶部，false为底部
  }

  componentDidMount() {
    getCountryList().then(countryList => {
      this.setState({countryList});
      // set country
      if (countryList.length > 0) {
        this.setState({countryList, country: countryList[0]});
        getCityList(countryList[0].id).then((cityList) => {
          // set city
          if (cityList.length > 0) {
            this.setState({cityList, city: cityList[0]});
            getStoreList(cityList[0].id).then((storeList) => {
              // set store
              this.setState({storeList});
            });
          }
        });
      }
    })
  }

  /**
   * change country
   */
  onChangeCountry = (e) => {
    // let countryId = e.target.value;
    let countryId = e;
    this.setState({optionVisible: {country: false, city: false}});

    getCityList(countryId).then((cityList) => {
      for (let item of this.state.countryList) {
        if (item.id === countryId) {
          this.setState({cityList, country: item, city: cityList[0] || null});
          if (cityList.length > 0) {
            // this.onChangeCity({target: {value: cityList[0].id}});
            this.onChangeCity(cityList[0].id);
          }
          break;
        }
      }
    });
  };

  /**
   * change city
   */
  onChangeCity = (e) => {
    console.log('country:', this.state.country);
    console.log('changeCity:', e);
    this.setState({optionVisible: {country: false, city: false}});
    for (let item of this.state.cityList) {
      if (item.id === e) {
        this.setState({city: item});
        break;
      }
    }
    getStoreList(e).then((storeList) => {
      this.setState({storeList});
    });
  };

  /**
   * click search
   */
  onClickShow = () => {
    // 点击关闭按钮
    if(this.state.showMap) {
      this.setState({showCity: true})
    }
    this.setState({showMap: !this.state.showMap});
    document.getElementById('content').scrollIntoView(true);
  };

  handleSearch = () => {
    this.setState({storeType: 0});
    if(localStorage.getItem('lang')!=='1') {
      this.child.changePoint(this.state.storeList[0].localPoint)
    }
    this.onClickShow()
  };

  /**
   * 重新加载地图
   */
  reloadMap = (store) => {
   this.child.changePoint(store.localPoint)
    document.getElementById('root').scrollIntoView(true);
  };

  /**
   * 展示隐藏门店营业时间
   * */
  toggleStoreDetail = (i) => {
    let store = this.state.storeList[i];
    store.showTime = !store.showTime;
    this.setState({storeList: this.state.storeList})
  };

  toggleOption = (e, item) => {
    e.stopPropagation()
    if (item === 'country') {
      this.setState({optionVisible: {country: !this.state.optionVisible.country, city: false}})
    } else {
      this.setState({optionVisible: {country: false, city: !this.state.optionVisible.city}})
    }
  };

  /*closeOption = (item) => {
    this.setState({optionVisible: {country: false, city: false}});
  };*/

  // 获取当前经纬度坐标
  getPosition = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        console.log('aaaaaa')
        navigator.geolocation.getCurrentPosition(function (position) {
          console.log('获取经纬度：',position)
          let latitude = position.coords.latitude
          let longitude = position.coords.longitude
          let data = {
            latitude: latitude,
            longitude: longitude
          }
          resolve(data)
        }, function () {
          reject(arguments)
        })
      } else {
        reject('你的浏览器不支持当前地理位置信息获取')
      }
    })
  };

  // 搜索最近30公里店铺
  getNearest = () => {
    this.getPosition().then(result => {
      console.log('搜索最近30公里店铺!!')
      // 返回结果示例：
      // {latitude: 30.318030999999998, longitude: 120.05561639999999}
      // 一般小数点后只取六位，所以用以下代码搞定
      let lng = String(result.longitude).match(/\d+\.\d{0,6}/)[0];
      let lat = String(result.latitude).match(/\d+\.\d{0,6}/)[0];
      console.log(lng, lat);
      getNearestStore(lng, lat).then(res => {
        if (res && res.length) {
          this.child.changePoint(res[0].localPoint,'hiddenCity');
          this.setState({storeList: res, storeType: 1});
          this.setState({showCity: false});
          this.onClickShow()
        }
      },err=> {
        this.onClickShow()
      });
    }).catch(err => {
      console.log(err)
    })
  };

  //替换所有的回车换行
  TransferString(content) {
    let string = content;
    try{
      string=string.replace(/\r\n/g,"<br>");
      string=string.replace(/\n/g,"<br>");
    }catch(e) {
      alert(e.message);
    }
    return string;
  }

  render() {
    return (
        <div className="content-box" id="content">
          {!this.state.showMap&&<img className="banner-img" src={require('../../assets/images/store_bg.jpg')}/>}
          <div className="choose-area" style={{display: !this.state.showMap ? "block" : "none"}}>
            <p className="title">{intl.get('Locate_A_store')}</p>
            <p className="title2"  onClick={this.getNearest}>{intl.get('SEARCH_AROUND_ME')}</p>
            <p className="title3">{intl.get('Locate_your_nearest_Shiatzy_Chen_boutique')}</p>
            <p className="or">
              or
            </p>
            <div className="selector">
              {/*<select name="country" onChange={this.onChangeCountry}>
                <option value='' disabled selected style={{display:'none'}}>{intl.get('Choose_Region')}</option>
                {this.state.countryList.map(item => (<option value={item.id} key={item.id}>{item.name}</option>))}
              </select>
              <select name="city" onChange={this.onChangeCity}>
                <option value='' disabled selected style={{display:'none'}}>{intl.get('Choose_Location')}</option>
                {this.state.cityList.map((item) => (<option value={item.id} key={item.id}>{item.name}</option>))}
              </select>*/}
              <div className="form_select">
                <input className="select" type="text" onClick={(event) => this.toggleOption(event, 'country')}
                       value={this.state.country.name} readOnly={true}/>
                {this.state.optionVisible.country && <ul className="list">
                  {this.state.countryList.map(item =>
                      (<li className={item.id === this.state.country.id ? 'selected':''}
                           onClick={() => this.onChangeCountry(item.id)}
                           key={item.id}>{item.name}</li>))}
                </ul>}
              </div>
              <div className="form_select">
                <input className="select" type="text" onClick={(event) => this.toggleOption(event, 'city')}
                       value={this.state.city.name} readOnly={true}/>
                {this.state.optionVisible.city && <ul className="list">
                  {this.state.cityList.map(item =>
                      (<li className={item.id === this.state.city.id ? 'selected':''}
                           onClick={() => this.onChangeCity(item.id)}
                           key={item.id}>{item.name}</li>))}
                </ul>}
              </div>
            </div>
            <button className="search" onClick={this.handleSearch}>{intl.get('SEARCH')}</button>
            <div className="tip">{intl.get('Please_choose_the_area_in_your_interest')}</div>
          </div>
          <div className="map-container" style={{display: this.state.showMap ? "block" : "none"}}>
            <div className="map">
              <MapLocation location={this.state.city.name} storeList={this.state.storeList} onRef={(ref)=>{ this.child = ref}}/>
            </div>
            <div className="address-list">
              <span className="close1 icon back" onClick={this.onClickShow}></span>
              <div className="adress-main">
                {/*<p className="address-title">{this.state.country.name + ' ' + intl.get('STORES')}</p>*/}
                {this.state.storeType?<p className="address-title">{intl.get('STORES')}</p>:
                    <p className="address-title">{intl.get('STORES1')}</p>}
                {this.state.storeType?<p className="address-2nd-title">{intl.get('points_for_your_search', {num: this.state.storeList.length})}</p>:
                    <p className="address-2nd-title">{intl.get('points_for_your_search1', {num: this.state.storeList.length})}</p>}
                {/*<p className="address-2nd-title">{intl.get('points_for_your_search', {num: this.state.storeList.length})}</p>*/}
                {this.state.showCity&&<p className="address-locate">{this.state.city.name}</p>}
                <ul className="store-list">
                  {this.state.storeList.map((store,index,arr) => {
                    return (
                        <li key={store.id}>
                          <p className="address-store-name">{store.name}</p>
                          <p style={{ width: '250px', margin: '0 auto 15px', lineHeight: '1.3'}}>{store.address}</p>
                          <p>Tel {store.tel}</p>
                          <p className="address-link">
                            <a href="javascript:void(0);" onClick={() => {
                              this.reloadMap(store)
                            }}>{intl.get('Find_on_map')}</a>
                            <span>|</span>
                            <a href="javascript:void(0);" onClick={() => {
                              this.toggleStoreDetail(index)
                            }}>{intl.get('Store_Detail')}</a>
                          </p>
                          {store.showTime &&
                            <p className="time-list">{store.time}</p>
                          }
                          {index<arr.length-1&&<hr/>}
                        </li>
                    );
                  })}
                </ul>
              </div>
            </div>

          </div>
        </div>
    )
  }
}
