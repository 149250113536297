import React from 'react';
import {getImg, getVideo, isVisible, checkFull, throttle} from 'utils';
import './index.scss';
import ReactPlayer from 'react-player';
import $ from 'jquery';

export default class BrandStory extends React.Component {
  constructor() {
    super();
    this.state = {
      list: [],
    }
  }

  componentDidMount() {
    console.log("componentDidMount")
    document.getElementById('root').scrollIntoView(true);
    $('.aniimg').removeClass('-on')
    $('.aniimg').addClass('-on')
    $('.text-content').addClass('fadeIn')
  }

  componentDidUpdate(){
    // $('.aniimg').addClass('-on')
    console.log("componentDidUpdate")
  }

  componentWillUnmount() {
    // window.onscroll = ''
    console.log("componentWillUnmount")
  }


  render() {
    // let url1, url2, url3, url4, url5, url6, url7, url8, title4, title5, title6, title7, title8, videoUrl4;
    let url1, url2, url3;
    const {data} = this.props;
    const imglist = data.imageResponses ? data.imageResponses : [];
    imglist.map((item, i) => {
      switch (i) {
        case 0:
          url1 = getImg(item.imageUrl).url;
          break;
        case 1:
          url2 = getImg(item.imageUrl).url;
          break;
        case 2:
          url3 = getImg(item.imageUrl).url;
          break;
      //   case 3:
      //     title4 = item.title;
      //     url4 = getImg(item.imageUrl).url;
      //     videoUrl4 = item.voidUrl ? getVideo(item.voidUrl).videoUrl : '';
      //     break;
      //   case 4:
      //     title5 = item.title;
      //     url5 = getImg(item.imageUrl).url;
      //     break;
      //   case 5:
      //     title6 = item.title;
      //     url6 = getImg(item.imageUrl).url;
      //     break;
      //   case 6:
      //     title7 = item.title;
      //     url7 = getImg(item.imageUrl).url;
      //     break;
      //   case 7:
      //     title8 = item.title;
      //     url8 = getImg(item.imageUrl).url;
      //     break;
        default:
        break;
      }
    });
    let newImgList = JSON.parse(JSON.stringify(imglist)).splice(3).reverse();
    newImgList.map((item, index, arry) => {
      item.imageUrl = getImg(item.imageUrl).url;
      item.voidUrl = item.voidUrl ? getVideo(item.voidUrl).videoUrl : '';
    });
    console.log(newImgList)

    const play = (<span className="icon-play"></span>);
    $('.aniimg').removeClass('-on')
    $('.aniimg').addClass('-on')
    $('.text-content').addClass('fadeIn')
    const extraStyle = localStorage.getItem('lang') === '1' || localStorage.getItem('lang') === '2' ? 'cn-style':'en-style';

    return (

        <div className="brand-story-main">
          <div className="big-img-panel">
            <div className="first-img aniimg">
              {url3&&<div className="img" style={{backgroundImage: `url(${url3})`}}></div>}
              <div className={`text-content ${extraStyle}`} dangerouslySetInnerHTML={{__html: data.intro}}></div>
            </div>
            <div className="text-content2" dangerouslySetInnerHTML={{__html: data.recordedUtterance}}></div>
            <div className="first-img">
              <img src={url1} alt='' />
            </div>
            <div className="text-content3" dangerouslySetInnerHTML={{__html: data.introduction}}></div>
          </div>



          {newImgList && newImgList.length > 0 && newImgList.map((item, i) => {
            if (item.voidUrl) {
              return (
                <div className="middle-panel" key={i}>
                  <div className="all">
                    <div className="title">
                      {item.title&&<span className="year">{parseInt(item.title)}</span>}
                      {item.title ? item.title.substring(4) : ''}
                    </div>
                    <div className="img-con">
                      <ReactPlayer className='react-player' url={item.voidUrl} playing={true}
                                  controls light={item.imageUrl} width='100%' height='210px'
                                  playIcon={play}/>
                    </div>
                  </div>
                </div>
            )} else if (!item.voidUrl) {
              return (
                <div className="middle-panel" key={i}>
                  <div className="all">
                    <div className="title">
                      {item.title&&<span className="year">{parseInt(item.title)}</span>}
                      {item.title ? item.title.substring(4) : ''}
                    </div>
                    {item.link ? (<a target="_blank" href={item.link}>
                    <div className="img-con">
                      <img src={item.imageUrl}/>
                    </div>
                    </a>)
                    :
                    (<div className="img-con">
                      <img src={item.imageUrl}/>
                    </div>)
                    }
                  </div>
                </div>
            )}
          })}
          {/* <div className="middle-panel">
            <div className="all">
              <div className="title">
                {title4&&<span className="year">{parseInt(title4)}</span>}
                {title4 ? title4.substring(4) : ''}
              </div>
              <div className="img-con">
                <ReactPlayer className='react-player' url={videoUrl4} playing={true}
                             controls light={url4} width='100%' height='210px'
                             playIcon={play}/>
              </div>
            </div>
            <div className="all">
              <div className="title">
                {title5&&<span className="year">{parseInt(title5)}</span>}
                {title5 ? title5.substring(4) : ''}
              </div>
              <div className="img-con">
                <img src={url5}/>
              </div>
            </div>
          </div> */}

          {/* <div className="middle-panel">
            <div className="all">
              <div className="title">
                {title6&&<span className="year">{parseInt(title6)}</span>}
                {title6 ? title6.substring(4) : ''}
              </div>
              <div className="img-con">
                <img src={url6}/>
              </div>
            </div>
          </div> */}


          {/* <div className="middle-panel">
            <div className="left">
              <div className="title">
                {title7&&<span className="year">{parseInt(title7)}</span>}
                {title7 ? title7.substring(4) : ''}
              </div>
              <div className="img-con">
                <img src={url7}/>
              </div>
            </div>
            <div className="right">
              <div className="title">
                {title8&&<span className="year">{parseInt(title8)}</span>}
                {title8 ? title8.substring(4) : ''}
              </div>
              <div className="img-con">
                <img src={url8}/>
              </div>
            </div>
          </div> */}

        </div>
    )
  }
}
