import React from 'react';
import Home from 'pages/home';
// import Stories from 'pages/stories';
import RunWay from 'pages/runWay';
import RunWayDetail from 'pages/runWayDetail';
import LookBook from './pages/lookBookList';
import LookBookDetail from './pages/lookBookDetail';
import Stories from './pages/stories';
import StoryDetail from './pages/storyDetail';
import Store from './pages/store';
import TabCon from './pages/tabContainer';
import Contact from './pages/contact';
import Privacy from './pages/privacy';

export const ROUTES_MAP = [
    {
        path: "/",
        exact: true,
        component: Home
    },
    {
        path: '/runway',
        exact: true,
        component: RunWay,
    },
    {
        path: '/runway/detail',
        exact: true,
        component: RunWayDetail,
    },
    {
        path: '/lookbook',
        exact: true,
        component: LookBook,
    },
    {
        path: '/lookbook/detail',
        component: LookBookDetail,
    },
    {
        path: '/store',
        component: Store,
    },
    {
        path: "/stories",
        component: Stories,
    },
    {
        path: "/story/detail",
        component: StoryDetail,
    },
    {
        path: '/brandStory',
        exact: true,
        component: TabCon,
    },
    {
        path: '/contact',
        component: Contact,
    },
    {
        path: '/privacy',
        component: Privacy,
        hideCommon: true
    },
];

