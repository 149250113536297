import React, {useContext, useState} from 'react';
import {AppContext} from 'src/app-context';
import CollapseList from '@/CollapseList';
import './index.scss';
import intl from "react-intl-universal";
import {Link} from "react-router-dom";
import $ from "jquery";

export default function CommonNav(props) {
  const {navList, navigationResponses} = useContext(AppContext);
  let [searchValue, onInputChange] = useState('');

  const doSearch = () => {
    if (searchValue) {
      window.location.href = `https://www.shiatzychen.com/goods/search?goodsName=${searchValue}`
    }
  };

  onInputChange = e => {
    searchValue = e.target.value
  };

  const toStorePage = () => {
    props.close();
    // this.props.history.push('/store')
  };

  $(document).ready(() => {
    if (props.visible) {
      $('#navBox').slideDown();
      $('#top').addClass('open')
    }
  });

  const close = (e) => {
    e.stopPropagation();
    $('#navBox').slideUp(800);
    $('#top').removeClass('open')
    setTimeout(() => {
      props.close()
    }, 700)
  };

  return (
      <div className="common-nav-box" id="navBox">
        <div id='top' className='top' onClick={close}>
          <div className="click-close" onClick={close}>
            <div id="cross" className="close-con">
              <span></span>
              <span></span>
            </div>
          </div>
          <a href='/' className='a-link'>
            <div className="logo"></div>
          </a>
        </div>

        <div className="search-box">
          <div className="content">
            <input type="text" placeholder={intl.get('search_placeholder')} onChange={onInputChange}/>
            <div className="search" onClick={doSearch}></div>
          </div>
        </div>

        <CollapseList list={navList} keyWord="name" close={close}/>

        <div className="footer">
          <div className="list">
            <a href='/u/account/index' style={{display: 'block'}}>
              <div className="item">
                <div className="name">
                  {intl.get('My_Account')}
                </div>

                <div className="opt collapse"></div>
              </div>
            </a>
            <div className="item">
              <div className="name">
                <a href={navigationResponses[0].link}>{intl.get('Login_in')}</a> | <a
                  href={navigationResponses[0].link}>{intl.get('Register')}</a>
              </div>
              <div className="opt collapse"></div>
            </div>
            {/*<div className="item">
              <div className="name">{intl.get('Select_other_regions')}</div>
              <div className="opt collapse"></div>
            </div>*/}
            <div className="item">
              <Link to='/store' onClick={close}>
                <div className="name">{intl.get('Locate_A_Store')}</div>
                <div className="opt collapse"></div>
              </Link>
            </div>
            <div className="item" onClick={props.setLang}>
              <div className="name">{intl.get('Select_language')}</div>
              <div className="opt collapse"></div>
            </div>
          </div>

          <div className="custom">
            <div className="title">{intl.get('CUSTOMER_SERVICE_LINE')}</div>
            <div className="item" style={{marginTop: '0.1rem'}}>{intl.get('phone')}</div>
            <div className="item">({intl.get('CHINA_ONLY')})</div>
            <div className="item">{intl.get('Monday_to_Friday_9AM_to_6PM')}</div>
            <div className="item" style={{marginTop: '0.1rem'}}>{intl.get('email')}</div>
            <div className="item">({intl.get('All_Regions')})</div>
          </div>
        </div>
      </div>
  )
}
