import config from 'src/config';

const qs = require('querystringify');


export function getQuery(query) {
  let search = window.location.hash ? window.location.hash.split('?')[1] : window.location.search;
  const params = qs.parse(search);
  return params[query]
}

export const getImg = data => {
  if(!Array.isArray(data)) {
    return {url: '', alt: ''}
  } else if (!data[0].file || !data[0].alt) {
    return {url: '', alt: ''}
  }
  const file = data[0].file;
  const alt = data[0].alt;
  let url = '';
  if (file.indexOf('http') > -1) {
    url = file
  } else {
    url = `${config.FILE_URL}${file}`;
  }
  return {
    url, alt
  }
};

export const getVideo = data => {
  if (typeof data === 'string') {
    let videoUrl = config.VIDEO_URL + data;
    return {
      videoUrl, title: ''
    }
  } else if(!Array.isArray(data)) {
    return {videoUrl: '', title: ''}
  } else {
    if (!data[0].file) {
      return {videoUrl: '', title: ''}
    }
    const file = data[0].file;
    const title = data[0].title;
    let videoUrl= '';
    if (file.indexOf('http') > -1) {
      videoUrl = file
    } else {
      videoUrl = config.VIDEO_URL + file;
    }

    return {
      videoUrl, title
    }
  }
};

// 元素是否在视频中央
export const isVisible = (dom) => {
  if (dom) {
    let rect = dom.getBoundingClientRect();
    return (
        rect.top >= 0 && rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)/1.75 &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
};

// 判断是否有全屏事件,全屏时放开静音
export const checkFull = () => {
  let isFull = document.fullscreenEnabled || window.fullScreen || document.webkitIsFullScreen || document.msFullscreenEnabled;
  // to fix : false || undefined == undefined
  if (isFull === undefined) {
    isFull = false;
  }
  return isFull;
};

// 节流函数
export const throttle = (fn, interval = 500) => {
  let run = true;

  return function () {
    if (!run) return;
    run = false;
    setTimeout(() => {
      fn.apply(this, arguments);
      run = true;
    }, interval);
  };
};