import {createContext} from 'react';

const mapAllNav = (list) => {
  list.map(item => {
    let path = item.path;
    let id = 0;
    switch (item.id) {
      case 223:
        id = 223;
        path = "/stories";
        break;
      case 222:
        id = 222;
        path = "/runway";
        break;
      default:
        path = item.path;
        break;
    }
    /*const allItem = {id: id, name: 'View All', path: path};
    let sub = item.children[0].children;
    if(item.children&&item.children.length) {
      item.children[0].children = sub ? [allItem, ...sub]: [allItem];
    } else {
      item.children[0].children = [allItem];
    }*/
  });
  return list
};

export const app = {
  navList: [],
  setNavList: list => app.navList = mapAllNav(list),
  navigationResponses: [], // 登录，注册链接地址
  getNavigationResponses: data => app.navigationResponses = data,
  langVisible: false,
  setLangVisible: bool => {
    console.log('执行了', bool);
    app.langVisible = bool
  },
};

export const AppContext = createContext(app);