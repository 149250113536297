import React from 'react';
import { Accordion, List } from 'antd-mobile';
import { Link } from "react-router-dom";
import './index.scss';
import $ from 'jquery';

export default function CollapseList(props) {
  const { keyWord, list, emailAddr, currentHref} = props;
  let showContact = false;
  let top = 0;

  const toTop = () => {
    document.getElementById('root').scrollIntoView(true);
  };

  return (
    <div style={{ margin: keyWord==='name'? '10px 0.14rem':'0' }}>
      <Accordion defaultActiveKey="0" className="my-accordion">
        {
          list.map(item => {
            const { children } = item;
            return (
              <Accordion.Panel header={item[keyWord]} key={item.id}>
                <List className="my-list">
                  {
                    children ?
                      <>
                          {
                              children.map(child => {
                                  /*头部*/
                                  if (keyWord === 'name') {
                                      let subChildren = child.children&&child.children.length? child.children: [];
                                      return subChildren.map(subChild => {
                                          return (
                                              <List.Item key={subChild.id}>
                                                {(subChild.id===222 || subChild.id===223)?
                                                   <Link to={subChild.path} onClick={props.close}>{subChild.name}</Link>
                                                :
                                                  <a href={subChild.path} onClick={props.close}>{subChild.name}</a>
                                                }
                                              </List.Item>
                                          )
                                      })

                                  } else {
                                      /*尾部*/
                                      if (currentHref === child.link) {
                                        return (
                                            <List.Item key={child.id}>
                                              <a className='currentUrl' href={child.link} onClick={toTop}>{child.title}</a>
                                            </List.Item>
                                        )
                                      } else {
                                        return (
                                            <List.Item key={child.id}>
                                              <a href={child.link}>{child.title}</a>
                                            </List.Item>
                                        )
                                      }
                                    // if (child.id !== 40) {
                                    //   /*点击当前页面的链接回到顶部*/
                                    // } else {
                                    //   return (
                                    //       <List.Item key={child.id}>
                                    //         <a onClick={props.openContact}>{child.title}</a>
                                    //       </List.Item>
                                    //   )
                                    // }
                                  }
                              })
                          }
                      </>
                      : null
                  }
                </List>
              </Accordion.Panel>
            )
          })
        }

      </Accordion>
    </div>
  )
}
